import router from "../router";
export function setupAxios(axios, store) {
  if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = "http://127.0.0.1:8000/api/v1/";
    axios.defaults.headers["api-key"] =
      "8cd8d8989c6bcab8aB7fsad361602bc6a5094a9m";
    console.log("Development Version");
  } else if (window.location.host === "admin.bashkatibnews.com") {
    axios.defaults.baseURL = "https://bashkatibnews.com/api/v1/";
    axios.defaults.headers["api-key"] =
      "8cd8d8989c6bcab8aB7fsad361602bc6a5094a9m";
  }

  axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";

  const userToken = store.getters.isToken || localStorage.getItem("token");
  if (userToken) {
    let userData = JSON.parse(localStorage.getItem("userData"));
    store.commit("setLoginData", userData);
    axios.defaults.headers.common.Authorization = `bearer ${userToken}`;
    axios.defaults.headers["content-type"] = "application/json";
    axios.defaults.headers.accept = "application/json";
    axios.defaults.headers["Accept-Language"] =
      localStorage.getItem("userLang") || "ar";
  }

  if (window.location.pathname != "/login") {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("userData");
          localStorage.removeItem("userPermissions");
          return router.push("/login");
        }
        return Promise.reject(error.response);
      }
    );
  }
}
