export function setupDirectives(appInstance, store) {
  const userPermissions =
    store.getters.userPermissions ||
    JSON.parse(localStorage.getItem("userPermissions"));
  const permissionsName = userPermissions?.map((perm) => perm.name);

  appInstance.directive("can", {
    mounted(el, binding, vnode) {
      if (!permissionsName.includes(binding.value)) {
        vnode.el.parentElement.removeChild(vnode.el);
      }
    },
  });
}
