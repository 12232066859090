import axios from "axios";
export default {
  state: {
    token: localStorage.getItem("token"),
    userToken: false,
    // userData: false,
    userData: localStorage.getItem("userData"),
    error: false,
    loading: false,
  },
  getters: {
    isError(state) {
      return state.error;
    },
    isLoading(state) {
      return state.loading;
    },
    isToken(state) {
      return state.userToken;
    },
    userName(state) {
      return state.userData?.name;
    },
    userImage(state) {
      return state.userData?.picture;
    },
    userRole(state) {
      return state.userData.role?.name;
    },
    userDomains(state) {
      return state.userData.domains;
    },
    userPermissions(state) {
      return state.userData?.permissions;
    },
    hasPermission: (state, getters) => (permissionName) => {
      return getters.userPermissions.some(
        (perm) => perm.name === permissionName
      );
    },
  },
  mutations: {
    setLoading(state, status) {
      state.loading = status;
    },
    setAccessToken(state, token) {
      state.userToken = token;
      // localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("token", token);
      axios.defaults.headers.common.Authorization = `bearer ${token}`;
    },
    setLoginData(state, data) {
      state.userData = data;
      localStorage.setItem("userData", JSON.stringify(data));
      localStorage.setItem("userPermissions", JSON.stringify(data.permissions));
    },
    setLoginError(state, error) {
      state.error = error;
    },
    setLogout(state) {
      state.token = null;
      state.userData = null;
    },
  },
  actions: {
    Login({ commit }, payload) {
      commit("setLoginError", false);
      commit("setLoading", true);
      return new Promise((reject) => {
        axios
          .post("auth/login", payload)
          .then((res) => {
            if (res.status == 200) {
              if (res.data.is_success) {
                commit("setAccessToken", res.data.data["access_token"]);
                commit("setLoginData", res.data.data["user"]);
                window.location.href = "/dashboard";
              }
            }
          })
          .catch((err) => {
            if (err.response.data.is_success == false) {
              commit("setLoginError", err.response.data.message);
            } else {
              commit("setLoginError", "Something went wrong");
            }
            reject(err);
          })
          .finally(() => commit("setLoading", false));
      });
    },
    Logout({ commit }) {
      return new Promise(() => {
        axios.post("auth/logout").then((res) => {
          if (res.status === 200) {
            if (res.data.is_success) {
              commit("setLogout");
              localStorage.removeItem("token");
              localStorage.removeItem("userData");
              localStorage.removeItem("userPermissions");
              delete axios.defaults.headers.common["Authorization"];
            }
          }
          window.location.href = "/login";
        });
      });
    },
    Update({ commit }, updatedData) {
      commit("setLoginData", updatedData);
    },
  },
};
