import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { setupI18n } from "./plugins/i18n";
import { setupAxios } from "./plugins/axios";
import { setupDirectives } from "./plugins/directives";

// Initialize i18n
const i18n = setupI18n();

// Initialize Axios
setupAxios(axios, store);

// Create Vue app instance
const appInstance = createApp(App);

// Use plugins
appInstance.use(router);
appInstance.use(store);
appInstance.use(ArgonDashboard);
appInstance.use(VueSweetalert2);
appInstance.use(i18n);

// Mount the app
appInstance.mount("#app");

// Setup custom directives
setupDirectives(appInstance, store);
