<template>
  <ul class="pagination justify-content-center">
    <li class="page-item prev-page" :class="page - 1 == 0 ? 'disabled' : page">
      <a class="page-link" @click="$emit('pagination-num', page - 1)"
        ><i class="fas fa-angle-left"></i
      ></a>
    </li>
    <span v-for="(num, index) in total_pages" :key="index">
      <li
        class="page-item"
        :class="page == num ? 'active' : ''"
        v-if="
          (num < page + 5 && num > page - 5) || num == total_pages || num == 1
        "
      >
        <a class="page-link" @click="$emit('pagination-num', num)">{{ num }}</a>
      </li>
    </span>
    <li
      class="page-item next-page"
      :class="page + 1 > total_pages ? 'disabled' : ''"
    >
      <a class="page-link" @click="$emit('pagination-num', page + 1)"
        ><i class="fas fa-angle-right"></i
      ></a>
    </li>
  </ul>
</template>
<script>
export default {
  props: ["page", "total_pages"],
};
</script>

<style scoped>
li {
  cursor: pointer;
}
</style>
