<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      // trader_logo: "",
      // trader_name: "",
      loginStatus: "",
      mobile: null,
      mobileNav: true,
      windowWidth: null,
    };
  },
  mounted() {
    this.updateToken();
  },
  computed: {
    // isLogged() {
    //   return this.$store.getters.isLogged;
    // },
  },
  methods: {
    updateToken() {
      let bashkatebLoginData = JSON.parse(
        localStorage.getItem("bashkatebLoginData")
      );
      if (bashkatebLoginData != null) {
        this.$store.commit("setLoginData", bashkatebLoginData);
        // this.$store.commit("setBashkatebLoginData", bashkatebLoginData);
      }
    },
    logout() {
      this.$store.dispatch("Logout").then(() => {});
    },
  },
};
</script>
