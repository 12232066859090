import { createI18n } from "vue-i18n";
import en from "../locales/en.json";
import ar from "../locales/ar.json";

export function setupI18n() {
  let userLang = localStorage.getItem("userLang") || "ar";
  if (userLang === "ar") {
    require("../assets/scss/style-rtl.css");
  }

  return createI18n({
    locale: userLang,
    messages: { en, ar },
  });
}
