import { createRouter, createWebHistory } from "vue-router";
import authMiddleware from "@/middleware/authMiddleware";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    meta: {
      requiresAuth: true,
    },
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/Dashboard.vue"),
      },
      // Start Domains
      {
        path: "/domains",
        name: "domains",
        component: () => import("../views/Domains/Domains.vue"),
        meta: {
          required_permissions: ["domainList"],
        },
      },
      {
        path: "/domain-form/:id?",
        name: "domain-form",
        component: () => import("../views/Domains/Form.vue"),
        meta: {
          required_permissions: ["domainView"],
        },
      },
      {
        path: "/domain-setting/:id",
        name: "domain-setting",
        component: () => import("../views/Domains/FormSetting.vue"),
        meta: {
          required_permissions: ["domainUpdate"],
        },
      },
      {
        path: "/domain-ads/:id",
        name: "domain-ads",
        component: () => import("../views/Domains/FormAds.vue"),
        meta: {
          required_permissions: ["adUpdate"],
        },
      },
      {
        path: "/domain-seo/:id",
        name: "domain-seo",
        component: () => import("../views/Domains/FormSeo.vue"),
        meta: {
          required_permissions: ["domainView", "seoUpdate"],
        },
      },
      // End Domains
      // Start Departments
      {
        path: "/departments",
        name: "departments",
        component: () => import("../views/Departments/Departments.vue"),
        meta: {
          required_permissions: ["departmentList"],
        },
      },
      {
        path: "/department-form/:id?",
        name: "department-form",
        component: () => import("../views/Departments/Form.vue"),
        meta: {
          required_permissions: ["departmentView"],
        },
      },
      {
        path: "/all-departments",
        name: "all-departments",
        component: () => import("../views/Departments/DepartmentsDomains.vue"),
        meta: {
          required_permissions: ["departmentList"],
        },
      },
      {
        path: "/department-ads/:id",
        name: "department-ads",
        component: () => import("../views/Departments/FormAds.vue"),
        meta: {
          required_permissions: ["adUpdate"],
        },
      },
      {
        path: "/department-seo/:id",
        name: "department-seo",
        component: () => import("../views/Departments/FormSeo.vue"),
        meta: {
          required_permissions: ["departmentView", "seoUpdate"],
        },
      },
      // End Departments
      // Start Languages
      {
        path: "/languages",
        name: "languages",
        component: () => import("../views/Languages/Languages.vue"),
        meta: {
          required_permissions: ["languageList"],
        },
      },
      {
        path: "/language-form/:id?",
        name: "language-form",
        component: () => import("../views/Languages/Form.vue"),
        meta: {
          required_permissions: ["languageView"],
        },
      },
      // End Languages
      // Start Roles
      {
        path: "/roles",
        name: "roles",
        component: () => import("../views/Roles/Roles.vue"),
        meta: {
          required_permissions: ["roleList"],
        },
      },
      {
        path: "/role-form/:id?",
        name: "role-form",
        component: () => import("../views/Roles/Form.vue"),
        meta: {
          required_permissions: ["roleView"],
        },
      },
      // End Roles
      // Start Users
      {
        path: "/users",
        name: "users",
        component: () => import("../views/Users/Users.vue"),
        meta: {
          required_permissions: ["userList"],
        },
      },
      {
        path: "/user-form/:id?",
        name: "user-form",
        component: () => import("../views/Users/Form.vue"),
        meta: {
          required_permissions: ["userView"],
        },
      },
      {
        path: "/update-profile",
        name: "update-profile",
        component: () => import("../views/Users/UpdateProfile.vue"),
        meta: {
          required_permissions: ["updateUserProfile"],
        },
      },
      // End Users
      // Start Ads
      {
        path: "/ads",
        name: "ads",
        component: () => import("../views/Ads/Ads.vue"),
        meta: {
          required_permissions: ["adList"],
        },
      },
      {
        path: "/ad-form/:id?",
        name: "ad-form",
        component: () => import("../views/Ads/Form.vue"),
        meta: {
          required_permissions: ["adView"],
        },
      },
      // End Ads
      {
        path: "/newsletter",
        name: "newsletter",
        component: () => import("../views/Newsletters/Newsletters.vue"),
        meta: {
          required_permissions: ["newsletterView"],
        },
      },
      {
        path: "/contacts",
        name: "contacts",
        component: () => import("../views/Contact/Contact.vue"),
        meta: {
          required_permissions: ["contactUsView"],
        },
      },
      {
        path: "/careers",
        name: "careers",
        component: () => import("../views/Careers/Careers.vue"),
        meta: {
          required_permissions: ["careerList"],
        },
      },
      {
        path: "/polls",
        name: "polls",
        component: () => import("../views/Careers/Careers.vue"),
        meta: {
          required_permissions: ["pollList"],
        },
      },
      {
        path: "/career-form/:id?",
        name: "career-form",
        component: () => import("../views/Careers/Form.vue"),
        meta: {
          required_permissions: ["careerView"],
        },
      },
      {
        path: "/poll-form/:id?",
        name: "poll-form",
        component: () => import("../views/Careers/Form.vue"),
        meta: {
          required_permissions: ["pollView"],
        },
      },
      {
        path: "/career-cvs/:id?",
        name: "career-cvs",
        component: () => import("../views/Careers/Cvs.vue"),
        meta: {
          required_permissions: ["careerView"],
        },
      },
      {
        path: "/poll-files/:id?",
        name: "poll-files",
        component: () => import("../views/Careers/Cvs.vue"),
        meta: {
          required_permissions: ["pollView"],
        },
      },
      {
        path: "/career-cv/:form_id/:file_id",
        name: "career-cv",
        component: () => import("../views/Careers/Cv.vue"),
        meta: {
          required_permissions: ["careerView"],
        },
      },
      {
        path: "/poll-file/:form_id/:file_id",
        name: "poll-file",
        component: () => import("../views/Careers/Cv.vue"),
        meta: {
          required_permissions: ["pollView"],
        },
      },
      {
        path: "/quizzes",
        name: "quizzes",
        component: () => import("../views/Quizzes/Quizzes.vue"),
        meta: {
          required_permissions: ["quizList"],
        },
      },
      {
        path: "/quiz-form/:id?",
        name: "quiz-form",
        component: () => import("../views/Quizzes/Form.vue"),
        meta: {
          required_permissions: ["quizView"],
        },
      },
      {
        path: "/quiz-answers/:domain/:id/:quiz_hash_id",
        name: "quiz-answers",
        component: () => import("../views/Quizzes/Answers.vue"),
        meta: {
          required_permissions: ["quizView"],
        },
      },
      // End Admin Routes
      // Start Articles Routes
      {
        path: "/articles/:status?",
        name: "articles",
        component: () => import("../views/Articles/Articles.vue"),
        meta: {
          required_permissions: ["articleList"],
        },
      },
      {
        path: "/article-form/:id?",
        name: "article-form",
        component: () => import("../views/Articles/Form.vue"),
        meta: {
          required_permissions: ["articleView"],
        },
      },
      {
        path: "/article-seo/:id",
        name: "article-seo",
        component: () => import("../views/Articles/FormSeo.vue"),
        meta: {
          required_permissions: ["articleView", "seoUpdate"],
        },
      },
      {
        path: "/reports/",
        name: "reports",
        component: () => import("../views/Reports/Form.vue"),
        meta: {
          required_permissions: ["reportView"],
        },
      },
      {
        path: "/tags",
        name: "tags",
        component: () => import("../views/Tags/Tags.vue"),
        meta: {
          required_permissions: ["tagList"],
        },
      },
      {
        path: "/tag-form/:id?",
        name: "tag-form",
        component: () => import("../views/Tags/Form.vue"),
        meta: {
          required_permissions: ["tagView"],
        },
      },
      {
        path: "/keywords",
        name: "keywords",
        component: () => import("../views/Keywords/Keywords.vue"),
        meta: {
          required_permissions: ["tagList"],
        },
      },
      {
        path: "/keyword-form/:id?",
        name: "keyword-form",
        component: () => import("../views/Keywords/Form.vue"),
        meta: {
          required_permissions: ["tagView"],
        },
      },
      // End Articles Routes
      // Start Cross Media
      {
        path: "/cross-media",
        name: "cross medial",
        component: () => import("../views/CrossMedia/CrossMedia.vue"),
        meta: {
          required_permissions: ["crossMediaControl"],
        },
      },
      {
        path: "/cross-media-form/:id?",
        name: "cross-media-form",
        component: () => import("../views/CrossMedia/Form.vue"),
        meta: {
          required_permissions: ["crossMediaControl"],
        },
      },
      // End Cross Media
    ],
  },
  {
    path: "/",
    redirect: "login",
    name: "Login",
    component: AuthLayout,
    meta: {
      hideForAuth: true,
    },
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
    ],
  },
];

const router = createRouter({
  linkActiveClass: "active",
  history: createWebHistory(process.env.BASE_URL),
  // mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  authMiddleware(to, from, next);
});
export default router;
