export default function authMiddleware(to, from, next) {
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const userRole = userData.role?.name;
  const userPermissions = localStorage.getItem("userPermissions") || []; // Assuming user permissions are an array

  // Check if route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token) {
      // If route has specific roles
      const metaRoles = to.meta.roles || [];
      if (metaRoles.length && !metaRoles.includes(userRole)) {
        next({ name: "dashboard" });
        return;
      }

      // If route has required permissions
      const metaPermissions = to.meta.required_permissions || [];
      const hasAllPermissions = metaPermissions.every((perm) =>
        userPermissions.includes(perm)
      );

      if (metaPermissions.length && !hasAllPermissions) {
        next({ name: "dashboard" }); // Redirect to dashboard if permissions fail
        return;
      }

      next(); // Proceed to the route
    } else {
      next({ name: "Login" }); // Redirect to Login if not authenticated
    }
  }
  // Routes that should be hidden for authenticated users
  else if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (token) {
      next({ path: "/" }); // Redirect authenticated users
    } else {
      next(); // Allow access for unauthenticated users
    }
  }
  // Default: Allow access to routes with no specific meta
  else {
    next();
  }
}
